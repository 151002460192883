export enum USER_TYPE {
    PHYSICAL = 'physical',
    JURIDICAL = 'juridical',
    INDIVIDUAL = 'individual'
}

export interface ErrorMessage {
    errors: Record<string, string[]>;
    message: string | null;
}

export const initialError: ErrorMessage = {
    errors: {},
    message: null
}

export interface Individual {
    inn: string
    firstname: string
    middlename: string
    lastname: string
    isSignatory: boolean;
    certificateFile: {
        ext: string
        uuid: string
        originalName: string
    };
    filled: boolean;
}

export interface Signatory {
    lastname: string;
    firstname: string;
    middlename: string;
    post: string;
    confidenceFile: {
        ext: string;
        uuid: string;
        originalName: string;
    };
    filled: boolean;
}

export interface Director {
    post: string
    lastname: string
    firstname: string
    middlename: string
    isSignatory: boolean
    protocolFile: {
        ext: string
        uuid: string
        originalName: string
    }
    filled: boolean
}

export interface Organization {
    inn: string
    kpp: string
    name: string
    address: string
    actualAddress: string
    okved: string
    country: string
    city: string
    innFile: {
        ext: string
        uuid: string
        originalName: string
    },
    filled: boolean
}

export interface Personal {
    id: number
    firstname: string
    middlename: string
    lastname: string
    email: string
    phone: string
    timezone: {
        city: string
        timezone: string
    };
}

export interface Passport {
    series: string
    number: string
    place: string
    date: string
    address: string
    filePassports: {
        ext: string
        uuid: string
        originalName: string
    }[];
    filled: boolean
}

export interface BankDetail {
    bic: string;
    bank: string;
    correspondentAccount: string;
    currentAccount: string;
    filled: boolean;
}

export interface IndividualInfo {
    personal: Personal;
    bankDetail: BankDetail;
    signatoryInfo: Signatory;
    individualInfo: Individual;
}

export interface OrganizationInfo {
    personal: Personal;
    bankDetail: BankDetail;
    directorInfo: Director;
    signatoryInfo: Signatory;
    organizationInfo: Organization;
}

export interface PersonalInfo {
    personal: Personal;
    passport: Passport;
    bankDetail: BankDetail;
}

export interface User {
    id: number;
    email: string;
    phone: string;
    createdAt: string;
    deactivatedAt: string;
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
    isVerified: boolean;
    isConfirmed: boolean;
    userType: USER_TYPE;
    userInfo: {
        id: number;
        firstname: string;
        middlename: string;
        lastname: string;
        email: string;
        phone: string;
        timezone: {
            city: string;
            timezone: string;
        }
    };
    moderation: {
        isOnModeration: boolean;
        comment: string;
    };
    setting: {
        commission: number;
    };
    userRoles: {
        id: number;
        role: string;
        name: string;
    }[];
}


export interface UserMailNotification {
    bidNotConfirmed: boolean
    newLots: boolean
    lotsSoonEndings: boolean
    lotSoonFinished: boolean
    bidConfirmed: boolean
}
